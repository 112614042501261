require('./bootstrap');


$(document).ready(function () {
    if($('#event-registration-form').length) {
        require('./modules/events/registration');
    }
});


